// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { SectionFieldRules } from "./SectionFieldRules";
import { SelectField } from "./SelectField";
import { StyleSheet, View } from "react-native";
import { fieldValuesOptions, templateStatusOptions } from "../constants";
import { findOptionByValue, newFieldRule, onLabel } from "../utils";
import { useAllTemplateOptions } from "@src/api/orgportal";
import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import type { FC } from "react";
import type { Option, PolicyValues } from "../types";

type SectionTemplateRuleProps = {
  readonly templateRuleIdx: number;
};

const { Some } = Helpers;

export const SectionTemplateRule: FC<SectionTemplateRuleProps> = ({ templateRuleIdx }) => {
  const { theme } = useTheme();
  const ctx = useFormikContext<PolicyValues>();
  const { allTemplates } = useAllTemplateOptions({ type: "Merit" });
  const [defaultTemplateOption, setDefaultTemplateOption] = useState<Option>();
  const templateRules = ctx.values.templateRules;

  useEffect(() => {
    if (allTemplates.length > 0) {
      const tmplId = templateRules[templateRuleIdx].templateId;
      if (Some(tmplId)) {
        setDefaultTemplateOption(findOptionByValue(allTemplates, tmplId));
      }
    }
  }, [allTemplates, templateRuleIdx, templateRules]);

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.default,
      borderRadius: theme.borderRadii.xl,
      flex: 1,
      gap: theme.spacing.m,
      marginBottom: theme.spacing.xxl,
      padding: theme.spacing.m,
    },
    horizontalRule: {
      borderBlockColor: theme.colors.border.default,
      borderBottomWidth: 1,
    },
    sectionContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      paddingHorizontal: theme.spacing.m,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.sectionContainer}>
        <Body size="l" style={{ marginRight: theme.spacing.l }}>
          Member has
        </Body>
        <View style={{ flex: 1 }}>
          <SelectField
            defaultValue={defaultTemplateOption}
            filterOption={onLabel}
            key={defaultTemplateOption?.value}
            label="Member has"
            name={`templateRules.${templateRuleIdx}.templateId`}
            onSelectOption={option => {
              const currentTemplateRule = ctx.values.templateRules[templateRuleIdx];
              if (
                currentTemplateRule.templateId !== null &&
                option.value !== currentTemplateRule.templateId &&
                currentTemplateRule.fieldValuesType === "specific"
              ) {
                ctx.setFieldValue(`templateRules.${templateRuleIdx}.fieldRules`, [newFieldRule()]);
              }
            }}
            options={allTemplates}
            placeholder={{ label: "Select merit", value: "" }}
            showLabel={false}
            usePortal
          />
        </View>
      </View>
      <View style={styles.sectionContainer}>
        <Body size="l" style={{ marginRight: theme.spacing.l }}>
          with
        </Body>
        <SelectField
          defaultValue={findOptionByValue(
            templateStatusOptions,
            templateRules[templateRuleIdx].templateStatus
          )}
          filterOption={onLabel}
          label="Status"
          name={`templateRules.${templateRuleIdx}.templateStatus`}
          options={templateStatusOptions}
          showLabel={false}
          usePortal
        />
        <Body size="l" style={{ marginHorizontal: theme.spacing.l }}>
          status and
        </Body>
        <SelectField
          defaultValue={findOptionByValue(
            fieldValuesOptions,
            templateRules[templateRuleIdx].fieldValuesType
          )}
          filterOption={onLabel}
          label="Field Values"
          name={`templateRules.${templateRuleIdx}.fieldValuesType`}
          onSelectOption={option => {
            if (option.value === "specific") {
              ctx.setFieldValue(`templateRules.${templateRuleIdx}.fieldRules`, [newFieldRule()]);
            } else {
              ctx.setFieldValue(`templateRules.${templateRuleIdx}.fieldRules`, []);
            }
          }}
          options={fieldValuesOptions}
          showLabel={false}
          usePortal
        />
        <Body size="l" style={{ marginLeft: theme.spacing.l }}>
          field values
        </Body>
      </View>
      {templateRules[templateRuleIdx].fieldValuesType === "specific" &&
        templateRules[templateRuleIdx].templateId !== null && (
          <>
            <View style={styles.horizontalRule} />
            <View style={styles.sectionContainer}>
              <SectionFieldRules
                templateRule={templateRules[templateRuleIdx]}
                templateRuleIdx={templateRuleIdx}
              />
            </View>
          </>
        )}
    </View>
  );
};
