// Copyright 2021 Merit International Inc. All Rights Reserved

import { Body } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Icon } from "../Icon";
import { Pressable, StyleSheet, View } from "react-native";
import { emptyFunction } from "../../test-utils/emptyFunction";
import { getTestProps } from "../../utils/testProps";
import { useTheme } from "../../theme/useTheme";
import React, { useState } from "react";
import ReactSelect from "react-select";
import type { Option, SelectProps, Size } from "./types";

const { Some } = Helpers;

const styles = StyleSheet.create({
  labelContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 4,
    zIndex: 2,
  },
});

const inputHeight: Record<Size, number> = {
  large: 48,
  medium: 40,
  small: 32,
};

export const Select = ({
  defaultValue,
  disabled,
  helpText,
  label,
  onSelectOption = emptyFunction,
  options,
  placeholder = { label: "Choose an option...", value: "" },
  rightElement,
  showLabel = true,
  size = "medium",
  testID,
  testProps,
  testUniqueID,
  usePortal = false,
  getOptionValue,
  filterOption,
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<Option>();
  const { theme } = useTheme();

  const tooltipTestProps = Some(testProps)
    ? { ...testProps, elementName: `${testProps.elementName}SelectTooltip` }
    : testProps;

  const labelTestProps = Some(testProps)
    ? { ...testProps, elementName: `${testProps.elementName}SelectLabel` }
    : testProps;

  return (
    <View
      {...getTestProps(
        { componentName: "Select", testID, testProps, testUniqueID },
        { componentName: "Select" }
      )}
    >
      <label htmlFor={label}>
        {/* label is always in DOM for screen readers and tests */}
        <View style={[styles.labelContainer, !showLabel && { display: "none" }]}>
          <Body testProps={labelTestProps}>{label}</Body>
          {Some(rightElement) && <View>{rightElement}</View>}
          {Boolean(helpText) && (
            <Pressable onPress={emptyFunction}>
              <Icon alt="tooltip" name="documentMediumDefault" testProps={tooltipTestProps} />
            </Pressable>
          )}
        </View>
      </label>

      <ReactSelect
        defaultValue={defaultValue}
        filterOption={filterOption}
        getOptionValue={getOptionValue}
        inputId={Some(testProps) ? `${testProps.elementName}-${testProps.screenName}` : label} // Required for testing
        instanceId={Some(testProps) ? `${testProps.elementName}-${testProps.screenName}` : label}
        isDisabled={disabled}
        menuPortalTarget={usePortal ? document.body : undefined}
        name={selectedOption?.label}
        noOptionsMessage={() => <Body color={theme.colors.text.subdued}>No options</Body>}
        onChange={option => {
          if (option === null) {
            return;
          }
          setSelectedOption(option);
          onSelectOption(option);
        }}
        options={options}
        placeholder={placeholder.label}
        styles={{
          control: provided => ({
            ...provided,
            fontFamily: theme.fonts.normal,
            minHeight: inputHeight[size],
          }),
          dropdownIndicator: provided => ({ ...provided, padding: "0px 8px" }),
          indicatorSeparator: () => ({}),
          input: provided => ({
            ...provided,
          }),
          menuList: provided => ({
            ...provided,
            ...theme.elevations.depth1,
          }),
          menuPortal: provided => ({
            ...provided,
            zIndex: 9999,
          }),
          option: provided => ({
            ...provided,
            color: theme.colors.text.default,
            fontFamily: theme.fonts.normal,
          }),
          valueContainer: provided => ({
            ...provided,
            padding: "0px 8px",
          }),
        }}
        theme={selectTheme => ({
          ...selectTheme,
          colors: {
            ...selectTheme.colors,
            primary: theme.colors.action.pressed,
            primary25: theme.colors.surface.hovered,
            primary50: theme.colors.surface.pressed,
          },
        })}
      />
    </View>
  );
};
