import { Helpers } from "@merit/frontend-utils";
import { useApi } from "@src/api/api";
import { useEffect, useState } from "react";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import type { ApiPolicy } from "@src/api/api";

const { Some } = Helpers;

export const usePolicy = (policyID: string | undefined): ApiPolicy | undefined => {
  const { selectedOrgId } = useLoggedInAuthState();
  const [policyDetails, setPolicyDetails] = useState<ApiPolicy | undefined>();
  const { policiesApi } = useApi();

  useEffect(() => {
    const getPolicy = async () => {
      if (policyID === undefined) {
        return;
      }
      try {
        if (Some(policyID)) {
          const response = await policiesApi.getApiPolicy({ orgID: selectedOrgId, policyID });
          setPolicyDetails(response);
        }
      } finally {
      }
    };

    getPolicy();
  }, [policiesApi, policyID, selectedOrgId]);

  return policyDetails;
};
