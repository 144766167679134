// Copyright 2021 Merit International Inc. All Rights Reserved

import { Platform } from "react-native";
import { Select as SelectDefault } from "./Select";
import { Select as SelectWeb } from "./Select.web";

export { createFilter } from "react-select";
export type { SelectProps, Option } from "./types";

export const Select = Platform.select({ default: SelectDefault, web: SelectWeb });
