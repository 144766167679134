/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetPolicies200ResponsePoliciesInnerResponseFormulasOwn } from './GetPolicies200ResponsePoliciesInnerResponseFormulasOwn';
import {
    GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFromJSON,
    GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFromJSONTyped,
    GetPolicies200ResponsePoliciesInnerResponseFormulasOwnToJSON,
} from './GetPolicies200ResponsePoliciesInnerResponseFormulasOwn';

import {
} from './index';

/**
 * 
 * @export
 * @interface CompoundAtom
 */
export interface CompoundAtom {
    /**
     * 
     * @type {string}
     * @memberof CompoundAtom
     */
    formulaType: CompoundAtomFormulaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CompoundAtom
     */
    target?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompoundAtom
     */
    predicate?: CompoundAtomPredicateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompoundAtom
     */
    arguments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompoundAtom
     */
    errorMessage?: string;
    /**
     * 
     * @type {GetPolicies200ResponsePoliciesInnerResponseFormulasOwn}
     * @memberof CompoundAtom
     */
    formula?: GetPolicies200ResponsePoliciesInnerResponseFormulasOwn;
}


/**
 * @export
 */
export const CompoundAtomFormulaTypeEnum = {
    Conjunction: 'Conjunction',
    Disjunction: 'Disjunction',
    CompoundAtom: 'CompoundAtom'
} as const;
export type CompoundAtomFormulaTypeEnum = typeof CompoundAtomFormulaTypeEnum[keyof typeof CompoundAtomFormulaTypeEnum];

/**
 * @export
 */
export const CompoundAtomPredicateEnum = {
    AfterNow: 'AfterNow',
    AfterThisDate: 'AfterThisDate',
    AfterThisDatetime: 'AfterThisDatetime',
    AfterThisTimeOfDay: 'AfterThisTimeOfDay',
    AfterToday: 'AfterToday',
    BeforeNow: 'BeforeNow',
    BeforeNowMinusXDuration: 'BeforeNowMinusXDuration',
    BeforeThisDate: 'BeforeThisDate',
    BeforeThisDatetime: 'BeforeThisDatetime',
    BeforeThisTimeOfDay: 'BeforeThisTimeOfDay',
    BeforeToday: 'BeforeToday',
    BeforeTodayMinusXDays: 'BeforeTodayMinusXDays',
    EqualToDate: 'EqualToDate',
    EqualToX: 'EqualToX',
    FieldHasValue: 'FieldHasValue',
    IsEmailDomain: 'IsEmailDomain',
    IsFalse: 'IsFalse',
    IsTrue: 'IsTrue',
    LessThanX: 'LessThanX',
    MatchesThisString: 'MatchesThisString',
    MoreThanX: 'MoreThanX',
    ReceivedXContainersFromTemplates: 'ReceivedXContainersFromTemplates',
    ReceivedContainersFromXTemplates: 'ReceivedContainersFromXTemplates',
    ReceivedFolioFromOrg: 'ReceivedFolioFromOrg',
    ReceivedMeritFromOrg: 'ReceivedMeritFromOrg',
    SentFolioToOrg: 'SentFolioToOrg'
} as const;
export type CompoundAtomPredicateEnum = typeof CompoundAtomPredicateEnum[keyof typeof CompoundAtomPredicateEnum];


/**
 * Check if a given object implements the CompoundAtom interface.
 */
export function instanceOfCompoundAtom(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "formulaType" in value;

    return isInstance;
}

export function CompoundAtomFromJSON(json: any): CompoundAtom {
    return CompoundAtomFromJSONTyped(json, false);
}

export function CompoundAtomFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompoundAtom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
    }
    return {
        
        'formulaType': json['formulaType'],
        'target': !exists(json, 'target') ? undefined : json['target'],
        'predicate': !exists(json, 'predicate') ? undefined : json['predicate'],
        'arguments': !exists(json, 'arguments') ? undefined : json['arguments'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'formula': !exists(json, 'formula') ? undefined : GetPolicies200ResponsePoliciesInnerResponseFormulasOwnFromJSON(json['formula']),
    };
}

export function CompoundAtomToJSON(value?: CompoundAtom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formulaType': value.formulaType,
        'target': value.target,
        'predicate': value.predicate,
        'arguments': value.arguments,
        'errorMessage': value.errorMessage,
        'formula': GetPolicies200ResponsePoliciesInnerResponseFormulasOwnToJSON(value.formula),
    };
}

