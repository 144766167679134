// Copyright 2024 Merit International Inc. All Rights Reserved

import { EditPolicy } from "../CreatePolicy/EditPolicy";
import { EditPolicyDeprecatedScreen } from "./EditPolicyDeprecated";
import { useFlags } from "launchdarkly-react-client-sdk";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";

export const EditPolicyScreen = () => {
  const { showM6CreateEditPolicyScreens } = useFlags<LDFeatureFlags>();

  if (showM6CreateEditPolicyScreens) {
    return <EditPolicy />;
  }

  return <EditPolicyDeprecatedScreen />;
};
