// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { FieldArray } from "formik";
import { SectionFieldRule } from "./SectionFieldRule";
import { SelectField } from "./SelectField";
import { StyleSheet, View } from "react-native";
import { fieldCombinationTypeOptions } from "../constants";
import { findOptionByValue, onLabel } from "../utils";
import type { FC } from "react";
import type { TemplateRule } from "../types";

type SectionFieldRulesProps = {
  readonly templateRule: Pick<TemplateRule, "fieldCombinationType" | "fieldRules" | "templateId">;
  readonly templateRuleIdx: number;
};

export const SectionFieldRules: FC<SectionFieldRulesProps> = ({
  templateRule,
  templateRuleIdx,
}) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      gap: theme.spacing.m,
    },
    fieldRuleContainer: {
      gap: theme.spacing.m,
    },
    sectionContainer: {
      alignItems: "center",
      flexDirection: "row",
      gap: theme.spacing.m,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.sectionContainer}>
        <Body>matching</Body>
        <SelectField
          defaultValue={findOptionByValue(
            fieldCombinationTypeOptions,
            templateRule.fieldCombinationType
          )}
          filterOption={onLabel}
          label=""
          name={`templateRules.${templateRuleIdx}.fieldCombinationType`}
          options={fieldCombinationTypeOptions}
          usePortal
        />
        <Body>of the following:</Body>
      </View>
      <View style={styles.fieldRuleContainer}>
        <FieldArray name={`templateRules.${templateRuleIdx}.fieldRules`}>
          {arr => (
            <>
              {templateRule.fieldRules.map((fieldRule, idx, array) => {
                if (templateRule.templateId !== null) {
                  return (
                    <SectionFieldRule
                      arr={arr}
                      disableDeleteButton={array.length <= 1}
                      fieldRule={fieldRule}
                      fieldRuleIdx={idx}
                      key={fieldRule.id}
                      showAddButton={idx === array.length - 1}
                      templateId={templateRule.templateId}
                      templateRuleIdx={templateRuleIdx}
                    />
                  );
                }

                return <></>;
              })}
            </>
          )}
        </FieldArray>
      </View>
    </View>
  );
};
