// Copyright 2023 Merit International Inc. All Rights Reserved

import * as AgentsApi from "../gen/agents/apis";
import * as IssuanceApi from "../gen/issuance/apis";
import * as WormholeApi from "../gen/wormhole/apis/";
import * as runtime from "@src/gen/org-portal/runtime";
import {
  Configuration as AgentsConfiguration,
  DefaultConfig as DefaultAgentsConfig,
} from "../gen/agents";
import {
  Configuration,
  DefaultConfig,
  EditPolicy200ResponseFromJSON,
  ExtendPolicy201ResponseFromJSON,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON,
  GetPolicies200ResponsePoliciesInnerRulesFromJSON,
  OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON,
} from "../gen/org-portal";
import { DefaultApi } from "@src/gen/org-portal";
import {
  DefaultConfig as DefaultIssuanceConfig,
  Configuration as IssuanceConfiguration,
} from "../gen/issuance";
import {
  DefaultConfig as DefaultWormholeConfig,
  Configuration as WormholeConfiguration,
} from "../gen/wormhole";
import { Helpers } from "@merit/frontend-utils";
import { InvalidPolicyError } from "@src/screens/Policies/utils";
import { exists } from "@src/gen/org-portal/runtime";
import { useAuthStore } from "@src/stores";
import { useMemo } from "react";
import Constants from "expo-constants";
import type {
  CompoundAtom,
  Conjunction,
  Disjunction,
  EditPolicy200Response,
  EditPolicyOperationRequest,
  EditPolicyRequest,
  ExtendPolicy201Response,
  ExtendPolicyOperationRequest,
  ExtendPolicyRequest,
  ExtendPolicyRequestFormula,
  GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner,
  GetPolicies200ResponsePoliciesInnerRules,
  GetPolicies200ResponsePoliciesInnerStateEnum,
  GetPolicyRequest,
  ResponseContext,
} from "../gen/org-portal";
import type { Configuration as EnvConfig } from "@src/configuration";

const { None, Some } = Helpers;

const envConfig = Constants.manifest?.extra as EnvConfig;

const BASE_API_CONFIG = {
  basePath: envConfig.api.orgPortal.baseUrl,
  headers: {},
};

type PolicyFieldRule = {
  readonly arguments: readonly string[];
  readonly errorMessage: string;
  readonly predicate: string;
  readonly target?: string;
};

type AuthParameters = {
  readonly authorization?: string;
  readonly xSessionStore?: string;
};

const buildAuthHeaders = (requestParameters: AuthParameters): runtime.HTTPHeaders => {
  const headerParameters: runtime.HTTPHeaders = {};

  // eslint-disable-next-line functional/immutable-data
  headerParameters["Content-Type"] = "application/json";

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (Some(requestParameters.authorization)) {
    // eslint-disable-next-line functional/immutable-data
    headerParameters.Authorization = String(requestParameters.authorization);
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (Some(requestParameters.xSessionStore)) {
    // eslint-disable-next-line functional/immutable-data
    headerParameters["X-Session-Store"] = String(requestParameters.xSessionStore);
  }

  return headerParameters;
};

/*
  These functions, and PolicyApi, which calls them,
  are required because our typescript swagger code generator does not support discriminated union types.
  What this does is override the api for ExtendPolicy and handles recursively nested formulas.
 */

// eslint-disable-next-line
const extendPolicyRequestTaggedUnionFormulaToJSON = (
  value?: ExtendPolicyRequestFormula | null
): object | null | undefined => {
  if (value === undefined) {
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (value === null) {
    return null;
  }
  switch (value.formulaType) {
    case "Disjunction":
      return {
        disjunction: (value as Disjunction).disjunction.map(a =>
          extendPolicyRequestTaggedUnionFormulaToJSON(a)
        ),
        formulaType: "Disjunction",
      };
    case "Conjunction":
      return {
        conjunction: (value as Conjunction).conjunction.map(a =>
          extendPolicyRequestTaggedUnionFormulaToJSON(a)
        ),
        formulaType: "Conjunction",
      };
    case "CompoundAtom":
      const ca = value as CompoundAtom;

      return {
        arguments: ca.arguments,
        errorMessage: ca.errorMessage,
        formula: extendPolicyRequestTaggedUnionFormulaToJSON(ca.formula),
        formulaType: "CompoundAtom",
        predicate: ca.predicate,
        target: ca.target,
      };
    default:
      const fr = value as unknown as PolicyFieldRule;

      return {
        arguments: fr.arguments,
        errorMessage: fr.errorMessage,
        formulaType: "CompoundAtom",
        predicate: fr.predicate,
        target: fr.target,
      };
  }
};

// eslint-disable-next-line
const extendPolicyRequestToJSON = (value?: ExtendPolicyRequest): any => {
  if (None(value)) {
    return undefined;
  }
  // eslint-disable-next-line
  const permissions = None(value.permissions)
    ? undefined
    : value.permissions.map(
        GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON
      );

  return {
    description: value.description,
    falseMessage: value.falseMessage,
    formula: extendPolicyRequestTaggedUnionFormulaToJSON(value.formula),
    name: value.name,
    permissions,
    // eslint-disable-next-line
    rule: (value.rule as readonly any[]).map(
      OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON
    ),
    sourcePolicyID: value.sourcePolicyID,
    trueMessage: value.trueMessage,
  };
};

// eslint-disable-next-line
const editPolicyRequestToJSON = (value?: EditPolicyRequest): any => {
  if (None(value)) {
    return undefined;
  }
  // eslint-disable-next-line
  const permissions = None(value.permissions)
    ? undefined
    : value.permissions.map(
        GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerToJSON
      );

  return {
    description: value.description,
    falseMessage: value.falseMessage,
    formula: extendPolicyRequestTaggedUnionFormulaToJSON(value.formula),
    name: value.name,
    permissions,
    // eslint-disable-next-line
    rule: (value.rule as readonly any[]).map(
      OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomToJSON
    ),
    trueMessage: value.trueMessage,
  };
};

type ApiFormula =
  | {
      readonly formulaType: "CompoundAtom";
      readonly formula?: ApiFormula;
      readonly arguments: readonly string[];
      readonly errorMessage?: string;
      readonly predicate: string;
      readonly target?: string;
    }
  | {
      readonly formulaType: "Conjunction";
      readonly conjunction: readonly ApiFormula[];
    }
  | {
      readonly formulaType: "Disjunction";
      readonly disjunction: readonly ApiFormula[];
    };

// eslint-disable-next-line
const parseApiFormulaRule = (json: any): ApiFormula => {
  if (None(json)) {
    throw new InvalidPolicyError(`Expected formula, got ${String(json)}`);
  }
  const formulaType = json.formulaType;
  if (formulaType === "Conjunction") {
    return {
      conjunction: (json.conjunction ?? []).map(parseApiFormulaRule),
      formulaType: "Conjunction",
    };
  } else if (formulaType === "Disjunction") {
    return {
      disjunction: (json.disjunction ?? []).map(parseApiFormulaRule),
      formulaType: "Disjunction",
    };
  } else if (formulaType === "CompoundAtom") {
    if (None(json.predicate)) {
      throw new InvalidPolicyError(`Expected predicate but got ${String(json.predicate)}`);
    }

    return {
      arguments: json.arguments ?? [],
      errorMessage: json.errorMessage,
      formula: Some(json.formula) ? parseApiFormulaRule(json.formula) : undefined,
      formulaType: "CompoundAtom",
      predicate: json.predicate,
      target: json.target,
    };
  }
  throw new Error(`Unexpected formula type ${String(formulaType)}`);
};

type ApiPolicyToplevelFormula = {
  readonly inherited: readonly ApiFormula[];
  readonly own: ApiFormula;
};

// eslint-disable-next-line
const parseApiFormulaRules = (json: any): ApiPolicyToplevelFormula => {
  if (json === undefined || json === null) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return json;
  }
  if (!exists(json, "inherited")) {
    throw new InvalidPolicyError("Expected inherited field");
  }

  if (!exists(json, "own")) {
    throw new InvalidPolicyError("Expected own formula field");
  }

  return {
    inherited: (json.inherited as readonly unknown[]).map(parseApiFormulaRule),
    own: parseApiFormulaRule(json.own),
  };
};

export type ApiPolicy = {
  readonly id: string;
  readonly name: string;
  readonly description?: string;
  readonly falseMessage: string;
  readonly trueMessage: string;
  readonly parent?: string;
  readonly createdBy?: string;
  readonly createdAt?: string;
  readonly shareablePermissions?: readonly string[];
  readonly permissions?: readonly GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInner[];
  readonly state: GetPolicies200ResponsePoliciesInnerStateEnum;
  readonly rules?: GetPolicies200ResponsePoliciesInnerRules | null;
  readonly ownerID?: string;
  readonly responseFormulas: ApiPolicyToplevelFormula;
};

const getPolicyResponseFromJSON = (json: object): ApiPolicy => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (json === undefined || json === null) {
    return json;
  }

  // @ts-expect-error parsing raw json
  const parsedFormula = Some(json.responseFormulas)
    ? // @ts-expect-error parsing raw json
      parseApiFormulaRules(json.responseFormulas)
    : {
        inherited: [],
        own: { conjunction: [], formulaType: "Conjunction" } as ApiFormula,
      };

  return {
    // @ts-expect-error parsing raw json
    createdAt: exists(json, "createdAt") ? json.createdAt : undefined,
    // @ts-expect-error parsing raw json
    createdBy: exists(json, "createdBy") ? json.createdBy : undefined,
    // @ts-expect-error parsing raw json
    description: exists(json, "description") ? json.description : undefined,
    // @ts-expect-error parsing raw json
    falseMessage: json.falseMessage,
    // @ts-expect-error parsing raw json
    id: json.id,
    // @ts-expect-error parsing raw json
    name: json.name,
    // @ts-expect-error parsing raw json
    ownerID: exists(json, "ownerID") ? json.ownerID : undefined,
    // @ts-expect-error parsing raw json
    parent: exists(json, "parent") ? json.parent : undefined,
    permissions: exists(json, "permissions")
      ? // @ts-expect-error parsing raw json
        (json.permissions as readonly unknown[]).map(
          GetDatasource200ResponseMappedTemplatesInnerTemplateFieldsInnerPermissionsInnerFromJSON
        )
      : undefined,
    responseFormulas: parsedFormula,
    rules: exists(json, "rules")
      ? // @ts-expect-error parsing raw json
        GetPolicies200ResponsePoliciesInnerRulesFromJSON(json.rules)
      : undefined,
    shareablePermissions: exists(json, "shareablePermissions")
      ? // @ts-expect-error parsing raw json
        json.shareablePermissions
      : undefined,
    // @ts-expect-error parsing raw json
    state: json.state,
    // @ts-expect-error parsing raw json
    trueMessage: json.trueMessage,
  };
};

// eslint-disable-next-line functional/no-class
class PolicyApi extends DefaultApi {
  public async extendPolicyRaw(
    requestParameters: ExtendPolicyOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ExtendPolicy201Response>> {
    // eslint-disable-next-line
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = buildAuthHeaders(requestParameters);

    // eslint-disable-next-line functional/no-this-expression
    const response = await this.request(
      {
        body: extendPolicyRequestToJSON(requestParameters.properties),
        headers: headerParameters,
        method: "POST",
        path: `/orgs/{orgID}/policies`.replace(
          `{${"orgID"}}`,
          encodeURIComponent(String(requestParameters.orgID))
        ),
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue =>
      ExtendPolicy201ResponseFromJSON(jsonValue)
    );
  }

  public async extendPolicy(
    requestParameters: ExtendPolicyOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ExtendPolicy201Response> {
    // eslint-disable-next-line functional/no-this-expression
    const response = await this.extendPolicyRaw(requestParameters, initOverrides);

    return response.value();
  }

  /**
   * Retrieve a Policy by id
   */
  public async getApiPolicyRaw(
    requestParameters: GetPolicyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ApiPolicy>> {
    const queryParameters = {};

    const headerParameters: runtime.HTTPHeaders = buildAuthHeaders(requestParameters);

    // eslint-disable-next-line functional/no-this-expression
    const response = await this.request(
      {
        headers: headerParameters,
        method: "GET",
        path: `/orgs/{orgID}/policies/{policyID}`
          .replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID)))
          .replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))),
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => getPolicyResponseFromJSON(jsonValue));
  }

  public async getApiPolicy(
    requestParameters: GetPolicyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ApiPolicy> {
    // eslint-disable-next-line functional/no-this-expression
    const response = await this.getApiPolicyRaw(requestParameters, initOverrides);

    return response.value();
  }

  public async editPolicyRaw(
    requestParameters: EditPolicyOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EditPolicy200Response>> {
    const queryParameters = {};

    const headerParameters: runtime.HTTPHeaders = buildAuthHeaders(requestParameters);

    // eslint-disable-next-line functional/no-this-expression
    const response = await this.request(
      {
        body: editPolicyRequestToJSON(requestParameters.policy),
        headers: headerParameters,
        method: "PATCH",
        path: `/orgs/{orgID}/policies/{policyID}`
          .replace(`{${"orgID"}}`, encodeURIComponent(String(requestParameters.orgID)))
          .replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))),
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue =>
      EditPolicy200ResponseFromJSON(jsonValue)
    );
  }

  /**
   * Edit Policy
   */
  public async editPolicy(
    requestParameters: EditPolicyOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EditPolicy200Response> {
    // eslint-disable-next-line functional/no-this-expression
    const response = await this.editPolicyRaw(requestParameters, initOverrides);

    return response.value();
  }
}

export const useApi = () => {
  const { accessToken, clear: clearAuthStore, session, setSession } = useAuthStore();

  return useMemo(() => {
    const checkForUnauthorized = async (context: ResponseContext): Promise<Response> => {
      const p = await new Promise<Response>((resolve, reject) => {
        if (context.response.status === 401) {
          clearAuthStore();
          reject(Error("401 Unauthorized"));
        } else {
          resolve(context.response);
        }
      });

      return p;
    };

    // Disabled temporarily
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const useFreshToken = async (context: ResponseContext): Promise<Response> => {
      const p = await new Promise<Response>(resolve => {
        if (context.response.headers.has("X-Session-Store")) {
          const newSession = context.response.headers.get("X-Session-Store");
          setSession(newSession);
        }

        resolve(context.response);
      });

      return p;
    };

    const getHeaders = () => {
      if (accessToken !== null && session !== null) {
        return {
          ...BASE_API_CONFIG.headers,
          Authorization: `Bearer ${accessToken}`,
          "X-Session-Store": session,
        };
      }
      if (accessToken !== null) {
        return {
          ...BASE_API_CONFIG.headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }

      return BASE_API_CONFIG.headers;
    };

    const notAuthorized = accessToken === null;
    const headers = getHeaders();
    const makeConfig = <T>(defaultConfig: T, url?: string): T => ({
      ...defaultConfig,
      basePath: url ?? BASE_API_CONFIG.basePath,
      headers,
      middleware: [notAuthorized ? {} : { post: checkForUnauthorized }],
    });

    const config = makeConfig(DefaultConfig);

    const api = new DefaultApi(new Configuration(config));

    const policiesApi = new PolicyApi(new Configuration(config));

    const issuanceApis = {
      containerTemplates: new IssuanceApi.ContainerTemplatesApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      containers: new IssuanceApi.ContainersApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      dataMapping: new IssuanceApi.DataMappingApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      entityMerges: new IssuanceApi.EntityMergesApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      fieldKinds: new IssuanceApi.FieldKindsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      infrastructure: new IssuanceApi.InfrastructureApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      mergeRequests: new IssuanceApi.MergeRequestsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      policies: new IssuanceApi.PoliciesApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      policyRequests: new IssuanceApi.PolicyRequestsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      search: new IssuanceApi.SearchApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      signedEntity: new IssuanceApi.SignedEntityApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
      templateFields: new IssuanceApi.TemplateFieldsApi(
        new IssuanceConfiguration(makeConfig(DefaultIssuanceConfig, envConfig.api.issuance.baseUrl))
      ),
    };

    const agentsApi = {
      agentEntityLinks: new AgentsApi.AgentEntityLinksApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
      agentVersions: new AgentsApi.AgentVersionsApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
      agents: new AgentsApi.AgentsApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
      infrastructure: new AgentsApi.InfrastructureApi(
        new AgentsConfiguration(makeConfig(DefaultAgentsConfig, envConfig.api.agents.baseUrl))
      ),
    };

    const wormholeApi = {
      dataSources: new WormholeApi.DataSourcesApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      files: new WormholeApi.FilesApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      infrastructure: new WormholeApi.InfrastructureApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      integrations: new WormholeApi.IntegrationsApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
      records: new WormholeApi.RecordsApi(
        new WormholeConfiguration(makeConfig(DefaultWormholeConfig, envConfig.api.wormhole.baseUrl))
      ),
    };

    return { agentsApi, api, config, issuanceApis, policiesApi, wormholeApi };
  }, [accessToken, clearAuthStore, setSession, session]);
};
