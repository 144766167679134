// Copyright 2024 Merit International Inc. All Rights Reserved

import { AssertIsDefined } from "@src/utils/asserts";
import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { type FC, useState } from "react";
import { FieldArray, useFormikContext } from "formik";
import { Helpers } from "@merit/frontend-utils";
import { RemoveModal, type RemoveModalState } from "./RemoveModal";
import { RuleCombinationTypeLabel } from "./RuleCombinationTypeLabel";
import { SectionTemplateRule } from "./SectionTemplateRule";
import { SelectField } from "./SelectField";
import { StyleSheet, View } from "react-native";
import { findOptionByValue, newTemplateRule, onLabel } from "../utils";
import { ruleCombinationTypeOptions } from "../constants";
import type { PolicyValues } from "../types";

type SectionTemplateRulesProps = unknown;

const { Some } = Helpers;

export const SectionTemplateRules: FC<SectionTemplateRulesProps> = () => {
  const { theme } = useTheme();
  const [removeModal, setRemoveModal] = useState<RemoveModalState>();
  const ctx = useFormikContext<PolicyValues>();

  const styles = StyleSheet.create({
    addAnotherRule: { width: 200 },
    container: {
      gap: theme.spacing.xxl,
    },
    errorsContainer: {
      backgroundColor: theme.colors.border.critical.disabled,
      borderRadius: 10,
      paddingHorizontal: 16,
      paddingVertical: 12,
    },
    noRulesContainer: {
      alignItems: "center",
      backgroundColor: theme.colors.background.default,
      borderRadius: theme.borderRadii.xl,
      justifyContent: "center",
      minHeight: 200,
    },
    ruleCombinationType: { alignItems: "center", flexDirection: "row" },

    rulesContainer: {
      minHeight: 200,
    },
  });

  const formHasTemplateRuleErrors = () => {
    if (
      ctx.dirty &&
      ctx.errors.templateRules !== undefined &&
      ctx.errors.templateRules.length > 0
    ) {
      return true;
    }

    return false;
  };

  const makeErrorMessage = (
    templateIndex: number | undefined,
    fieldIndex: number | undefined,
    error: string | undefined
  ) => {
    if (error === undefined) {
      return undefined;
    }
    if (fieldIndex === undefined) {
      if (templateIndex === undefined) {
        return `${error}`;
      }

      return `Error with Template Rule ${templateIndex}: ${error}`;
    }

    AssertIsDefined(templateIndex, "templateIndex must be defined if fieldIndex is undefined");

    return `Error with Field Rule ${fieldIndex} for Template Rule ${templateIndex}: ${error}`;
  };

  const templateRulesError = ctx.errors.templateRules;
  const hasTemplateRulesError = Some(templateRulesError);
  const hasMultipleTemplateRulesErrors = hasTemplateRulesError && Array.isArray(templateRulesError);
  const hasSingleTemplateRuleError = hasTemplateRulesError && !hasMultipleTemplateRulesErrors;

  return (
    <View style={styles.container}>
      <Heading bold level="3">
        Members will pass this policy if…
      </Heading>

      {formHasTemplateRuleErrors() && (
        <View style={styles.errorsContainer}>
          {hasMultipleTemplateRulesErrors &&
            ctx.errors.templateRules?.map((tr, templateIndex) => (
              <>
                <Body color={theme.colors.border.critical.pressed}>
                  {makeErrorMessage(templateIndex, undefined, tr?.templateId)}
                </Body>
                <Body color={theme.colors.border.critical.pressed}>
                  {makeErrorMessage(templateIndex, undefined, tr?.templateStatus)}
                </Body>
                <Body color={theme.colors.border.critical.pressed}>
                  {makeErrorMessage(templateIndex, undefined, tr?.fieldValuesType)}
                </Body>
                <Body color={theme.colors.border.critical.pressed}>
                  {makeErrorMessage(templateIndex, undefined, tr?.fieldCombinationType)}
                </Body>
                {tr?.fieldRules?.map((fr, fieldIndex) => (
                  <>
                    <Body color={theme.colors.border.critical.pressed}>
                      {makeErrorMessage(templateIndex, fieldIndex, fr?.predicate)}
                    </Body>
                    <Body color={theme.colors.border.critical.pressed}>
                      {makeErrorMessage(templateIndex, fieldIndex, fr?.templateFieldId)}
                    </Body>
                    <Body color={theme.colors.border.critical.pressed}>
                      {makeErrorMessage(templateIndex, fieldIndex, fr?.value)}
                    </Body>
                  </>
                ))}
              </>
            ))}

          {hasSingleTemplateRuleError && (
            <Body color={theme.colors.border.critical.pressed}>
              {makeErrorMessage(
                undefined,
                undefined,
                ctx.errors.templateRules as unknown as string
              )}
            </Body>
          )}
        </View>
      )}

      {ctx.values.templateRules.length > 1 && (
        <View style={styles.ruleCombinationType}>
          <Body size="l" style={{ marginRight: theme.spacing.l }}>
            they meet
          </Body>
          <SelectField
            defaultValue={findOptionByValue(
              ruleCombinationTypeOptions,
              ctx.values.ruleCombinationType
            )}
            filterOption={onLabel}
            label="They Meet"
            name="ruleCombinationType"
            options={ruleCombinationTypeOptions}
            showLabel={false}
            usePortal
          />
          <Body size="l" style={{ marginLeft: theme.spacing.l }}>
            of the following rules:
          </Body>
        </View>
      )}

      <View
        style={
          ctx.values.templateRules.length === 0 ? styles.noRulesContainer : styles.rulesContainer
        }
      >
        <FieldArray name="templateRules">
          {arr => (
            <>
              {ctx.values.templateRules.length === 0 && (
                <Button
                  iconLeft="addSmallDefault"
                  onPress={() => {
                    arr.push(newTemplateRule());
                  }}
                  text="Add rule"
                />
              )}
              <View>
                {ctx.values.templateRules.map((rule, idx) => (
                  <View key={rule.id} style={{ flexDirection: "row" }}>
                    <SectionTemplateRule key={rule.id} templateRuleIdx={idx} />
                    <Button
                      disabled={ctx.values.templateRules.length === 1}
                      iconLeft="trash"
                      onPress={() => {
                        setRemoveModal({
                          onContinue: () => {
                            arr.remove(idx);
                          },
                        });
                      }}
                      shape="circle"
                      style={{
                        marginLeft: theme.spacing.xl,
                        marginTop: theme.spacing.xxl,
                      }}
                      type="destructive"
                    />
                    {idx !== 0 && (
                      <RuleCombinationTypeLabel type={ctx.values.ruleCombinationType} />
                    )}
                  </View>
                ))}
                {ctx.values.templateRules.length > 0 && (
                  <View style={styles.addAnotherRule}>
                    <Button
                      iconLeft="addSmallDefault"
                      onPress={() => {
                        arr.push(newTemplateRule());
                      }}
                      text="Add another rule"
                      type="secondary"
                    />
                  </View>
                )}
                {removeModal !== undefined && (
                  <RemoveModal
                    onPressCancel={() => {
                      setRemoveModal(undefined);
                    }}
                    onPressContinue={() => {
                      removeModal.onContinue?.();
                      setRemoveModal(undefined);
                    }}
                    type="rule"
                  />
                )}
              </View>
            </>
          )}
        </FieldArray>
      </View>
    </View>
  );
};
